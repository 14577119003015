body {
  margin: 0;
  font-family: 'Lucinda Grande';
  color: #1d3463;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  height: 110px;
  background-color: #f5f5f5;
  padding: 10px; 
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px;
}

.bold { font-weight: 600; }
.nowrap { white-space: nowrap; }
.responsive { width: 30%; max-width: 325px; height: auto; }
